import { useMutation } from '@tanstack/react-query';
import { hasText } from '../../../helpers/functions';
import { setGtmDataLayer } from '../../../helpers/gtm';
import { useActionMakeRequest } from '../../makeRequest';
import { getSessionId } from '../utils';
import { eventKeys } from './eventKeys';

export type SendTrackEventParam = {
  eventName: string;
  gtmGlobalProperties?: Record<string, unknown>;
  apiGlobalProperties?: Record<string, unknown>;
  commonProperties?: Record<string, unknown>;
};
export const useSendTrackEvent = () => {
  const { actionCall: action } = useActionMakeRequest('sendTrackEvent', {
    shouldRedirectOnError: false,
  });

  return useMutation({
    mutationKey: eventKeys.mutations.sendTrackEvent(),
    mutationFn: async ({
      eventName,
      gtmGlobalProperties,
      apiGlobalProperties,
      commonProperties,
    }: SendTrackEventParam) => {
      const sessionId = getSessionId();
      if (hasText(sessionId)) {
        try {
          setGtmDataLayer({
            event: eventName,
            userId: sessionId,
            ...gtmGlobalProperties,
            ...commonProperties,
          });

          return await action({
            event: eventName,
            properties: {
              ...apiGlobalProperties,
              ...commonProperties,
            },
          });
        } catch (err: any) {
          console.error(`Event API error: ${err.message}`, err);
        }
      } else {
        console.info('Track event was not send, user id is missing.');
      }

      return Promise.resolve();
    },
  });
};
