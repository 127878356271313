import React, { Suspense, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { initReactI18next } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getCookie, removeCookie, setCookie } from 'helpers/cookies';
import { FontLoader } from 'helpers/font';
import { getNameByPath } from 'helpers/pages';
import { useAdcRestoreQuote } from 'helpers/quote';
import { isValidURL } from 'helpers/url';
import useSegment from 'hooks/useSegment';
import { clearCookies, useStartOver } from 'hooks/useStartOver';
import useUrlQuery from 'hooks/useUrlQuery';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { makeStyles } from 'tss-react/mui';
import Cookies from 'universal-cookie';
import ClubContextProvider from 'components/contexts/ClubContext';
import CoveragesContextProvider from 'components/contexts/CoveragesContext';
import DiscountContextProvider from 'components/contexts/DiscountContext';
import DriverContextProvider from 'components/contexts/DriverContext';
import ErrorPageProvider from 'components/contexts/ErrorPageContext';
import InsuredContextProvider from 'components/contexts/InsuredContext';
import ModalContextProvider from 'components/contexts/ModalContext';
import PropertyContextProvider from 'components/contexts/PropertyContext';
import QuoteContextProvider from 'components/contexts/QuoteContext';
import VehicleContextProvider from 'components/contexts/VehicleContext';
import { useActionCreators } from './api/actions';
import useRequest from './api/makeRequest';
import { InitData } from './api/schema/initialize.schema';
import BackHandlerContextProvider from './components/contexts/BackHandlerContext';
import { CircularLoader } from './components/Loading';
import QuoteRouter from './components/QuoteRouter';
import Redirect from './components/Redirect';
import { useDeviceTypes } from './helpers/devices';
import { convertObjectTypes } from './helpers/functions';
import { setLocalStorageItem } from './helpers/localStorage';
import pages, { getPathByName, PagePath } from './helpers/pages';
import { getBundlePolicyTypeFromParam } from './helpers/policy-and-residence';
import locales from './locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: import.meta.env.PROD === false,
    detection: {
      order: [
        'navigator',
        'cookie',
        'querystring',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    resources: locales,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull: false,
  });

const cookies = new Cookies();

const rootStyles = makeStyles()({
  root: {
    fontFeatureSettings: 'lnum',
  },
});

let browserCloseRequestFn: () => void;

function App() {
  const { classes } = rootStyles();
  const {
    actionCreators: { initialize, adcInitialize, sessionStatus },
  } = useActionCreators();
  const makeRequest = useRequest();
  const [sessionId, setSessionId] = useState(cookies.get('sessionId'));
  const [initalizedOnLocalState, setInitalizedOnLocalState] = useState(false);
  const isFresh = !getCookie('initiatedQuote');
  const history = useHistory();
  const {
    getParam,
    getUrlQuery,
    getAdvertisingParam: advertiser,
  } = useUrlQuery();
  const { track } = useSegment();
  const { startOver } = useStartOver();
  const restoreQuote = useAdcRestoreQuote();

  // Loading
  const name = getNameByPath(window.location.pathname);
  const arePNIPagesLoading =
    !isFresh &&
    ['Entry', 'TellUsAboutYou', 'Address', 'PurchaseConfirmation'].includes(
      name,
    ); // Only show loading when there is hydration (not fresh)
  const areOtherPagesLoading = false; // TODO: Extend it for other routes to display loader on refresh
  const [isLoaderHidden, setIsLoaderHidden] = useState<boolean>(false);
  const queryParamsFromCookie = getCookie('queryParam');

  const markSessionAsActiveAndStoreItInCookieFor90min = (sessionId: string) => {
    if (cookies.get('activeSessionId') !== sessionId) {
      setCookie('activeSessionId', sessionId, { maxAge: 60 * 90 });
      makeRequest(() => sessionStatus({ isActive: true }));
    }
  };

  const markSessionAsExpiredAndClearActiveCookie = () => {
    makeRequest(() => sessionStatus({ isActive: false }));
    removeCookie('activeSessionId');
  };

  const addBeforeUnloadEventListener = (sessionId: string | undefined) => {
    if (!sessionId) return;

    markSessionAsActiveAndStoreItInCookieFor90min(sessionId);

    window.removeEventListener('beforeunload', browserCloseRequestFn);
    browserCloseRequestFn = () => {
      markSessionAsExpiredAndClearActiveCookie();
    };
    window.addEventListener('beforeunload', browserCloseRequestFn);
  };

  const setParamsOnCookie = () => {
    const urlQuery = getUrlQuery();
    const queryParam: any = {};
    urlQuery.forEach((v, k) => {
      queryParam[k] = v;
    });
    setCookie('queryParam', JSON.stringify(queryParam));

    if (queryParam.bundle) {
      const paramBundlePolicyType = getBundlePolicyTypeFromParam(
        queryParam.bundle,
      );
      track('Bundle Data Received', {
        assigned_product: paramBundlePolicyType ?? 'None',
        referred_product: queryParam.bundle,
        referring_url: document.referrer,
      });
    }
  };

  useEffect(() => {
    const hasQueryParams = history.location.search;
    if (hasQueryParams) {
      setParamsOnCookie();
      setCookie('source', getParam('source'));
    }

    if (name === 'ViewSavedQuote' || name === 'PurchaseConfirmation')
      setIsLoaderHidden(true);
  }, []);

  useEffect(() => {
    return history.listen(() => {
      const prevCookie = cookies.get('previousUrl');
      const updatedCookie = prevCookie
        ? [
            typeof prevCookie !== 'string' ? prevCookie.pop() : prevCookie,
            window.location.href,
          ]
        : window.location.href;
      // There can be situations where because of history.replace
      // same url can be both current and previous
      const _updatedCookie = updatedCookie?.[0];
      if (_updatedCookie === window.location.href) return;
      setCookie('previousUrl', updatedCookie, {
        path: '/',
        httpOnly: false,
        sameSite: 'lax',
      });
      if (!isValidURL(_updatedCookie)) return;
      const prevPathName = new URL(_updatedCookie).pathname;
      const currentPathName = window.location.pathname;
      const paths = pages.map(({ path }) => path);
      const prevIndex = paths.indexOf(prevPathName as PagePath);
      const currIndex = paths.indexOf(currentPathName as PagePath);
      const direction =
        (currIndex > prevIndex && 'forward') ||
        (currIndex < prevIndex && 'backward') ||
        'none';
      setCookie('direction', direction, {
        path: '/',
        httpOnly: false,
        sameSite: 'lax',
      });
    });
  }, []);

  useEffect(() => {
    if (sessionId) {
      setTimeout(() => {
        setIsLoaderHidden(!areOtherPagesLoading);
        // Better UX when it loads at least a second
      }, 1000);
    }
  }, [isFresh, arePNIPagesLoading, areOtherPagesLoading]);

  useEffect(() => {
    return history.listen(() => {
      const hasQueryParams = history.location.search;
      hasQueryParams && setParamsOnCookie();
    });
  }, [history]);

  const isQuoteInitiated = getCookie('initiatedQuote');
  const isHomeRoute = history.location.pathname === '/';
  const isViewSavedQuoteRoute =
    history.location.pathname === '/view-saved-quote';
  const isAdcRoute = history.location.pathname === '/redirect';
  const isMaintenanceRoute = history.location.pathname === '/maintenance';
  const isHealthCheckRoute = history.location.pathname === '/health';
  const hasQueryParams = history.location.search;
  const needsInitialization = !isQuoteInitiated || hasQueryParams;
  const { segmentDeviceType } = useDeviceTypes();

  useEffect(() => {
    if (
      !isMaintenanceRoute &&
      (isHomeRoute || isViewSavedQuoteRoute) &&
      !initalizedOnLocalState
    ) {
      //according to POQT-5109 ... app should be initalized everytime base url is hit
      setInitalizedOnLocalState(true);
      clearCookies();
      setIsLoaderHidden(false);
      const secure = import.meta.env.PROD;

      makeRequest(
        () => {
          const urlQuery = new URLSearchParams(history?.location?.search);
          const urlQueryParams = Object.fromEntries(urlQuery.entries());

          const urlParams = convertObjectTypes({
            ...queryParamsFromCookie,
            ...{ source: getCookie('source') },
            ...urlQueryParams,
          });

          urlParams.source = urlParams?.source || 'club'; //If the URL parameter doesn't include a source, set the default value to 'club'.

          return initialize({
            urlParams,
            referringUrl: document.referrer,
            deviceType: segmentDeviceType,
            advertiser,
          }).catch((e) => {
            // NOTE: Every time there is an error during the initialization
            // a redirect to the error page happens, however the loading page
            // doesn't go away. The line below fixes the issue.
            setIsLoaderHidden(true);
            throw e;
          });
        },
        (res: InitData) => {
          setCookie('sessionId', res?.sessionId ? res.sessionId : null, {
            path: '/',
            secure: secure,
            httpOnly: false,
            sameSite: 'lax',
          });

          setLocalStorageItem('myq_previous_session_id', res?.sessionId ?? '');

          setSessionId(res?.sessionId);
          addBeforeUnloadEventListener(res?.sessionId);
        },
      ).then((res) => {
        !res?.melissaToken &&
          track('Token Creation Failed', {
            eventType: 'Automated System Process',
            token_name: 'melissa',
            reason: 'Melissa Token Creation Failed',
          });
        setCookie('melissaToken', res?.melissaToken);
        setCookie('featureFlags', res?.featureFlags);
        setCookie('chatToken', res?.chat, {
          path: '/',
          secure: secure,
          httpOnly: false,
          sameSite: 'lax',
        });

        hasQueryParams && setParamsOnCookie();

        setCookie('segmentIdSet', true);

        if (res?.maintenance) {
          history.push(getPathByName('Maintenance'));
        }

        setIsLoaderHidden(true);
      });
    } else {
      addBeforeUnloadEventListener(sessionId);
    }
  }, [isHomeRoute, needsInitialization, initalizedOnLocalState]);

  useEffect(() => {
    if (isAdcRoute) {
      clearCookies();
      const hasQueryParams = history?.location?.search;
      if (hasQueryParams) {
        setParamsOnCookie();
      }
      makeRequest(
        () => {
          const urlQuery = new URLSearchParams(history?.location?.search);
          const urlQueryParams = Object.fromEntries(urlQuery.entries());

          const urlParams = convertObjectTypes({
            ...queryParamsFromCookie,
            ...urlQueryParams,
          });

          return adcInitialize({
            urlParams,
            referringUrl: document.referrer,
            deviceType: segmentDeviceType,
            advertiser,
          });
        },
        (response) => {
          if (!response) return startOver();
          setCookie('adcFlow', true);
          return response;
        },
      )
        .then((response) => response && restoreQuote(response))
        .catch(() => startOver());
    }
  }, [isAdcRoute]);

  if (isHealthCheckRoute) {
    return <></>;
  }

  if (isMaintenanceRoute) {
    return (
      <Suspense fallback={<></>}>
        <FontLoader />
        <QuoteRouter />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <FontLoader />
      <div className={classes.root} data-testid={'root'}>
        {!isLoaderHidden && <CircularLoader />}
        {isLoaderHidden && (
          <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY ?? ''}
            useEnterprise={true}
          >
            <ModalContextProvider>
              <ErrorPageProvider>
                <InsuredContextProvider>
                  <ClubContextProvider>
                    <VehicleContextProvider>
                      <DriverContextProvider>
                        <DiscountContextProvider>
                          <PropertyContextProvider>
                            <CoveragesContextProvider>
                              <QuoteContextProvider>
                                <BackHandlerContextProvider>
                                  <Redirect />
                                  <QuoteRouter />
                                </BackHandlerContextProvider>
                              </QuoteContextProvider>
                            </CoveragesContextProvider>
                          </PropertyContextProvider>
                        </DiscountContextProvider>
                      </DriverContextProvider>
                    </VehicleContextProvider>
                  </ClubContextProvider>
                </InsuredContextProvider>
              </ErrorPageProvider>
            </ModalContextProvider>
          </GoogleReCaptchaProvider>
        )}
      </div>
    </Suspense>
  );
}

export default App;
