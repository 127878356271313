import React from 'react';
import { PolicyType } from 'api/schema/insured.schema';

const AdditionalInsuredPageForm = React.lazy(
  () => import('components/forms/AdditionalInsuredPageForm'),
);
const AddressPageForm = React.lazy(
  () => import('components/forms/AddressPageForm'),
);
const ClaimsPageForm = React.lazy(
  () => import('components/forms/ClaimsPageForm'),
);
const CommercialPurposesForm = React.lazy(
  () => import('components/forms/CommercialPurposesForm'),
);
const CompanyInfoPageForm = React.lazy(
  () => import('components/forms/CompanyInfoPageForm'),
);
const ConfirmDriverLicensePageForm = React.lazy(
  () => import('components/forms/ConfirmDriverLicensePageForm'),
);
const ConstructionTypesPageForm = React.lazy(
  () => import('components/forms/ConstructionTypesPageForm'),
);

const CurrentCarrierAaaPageForm = React.lazy(
  () => import('components/forms/CurrentCarrierAaaPageForm'),
);

const CurrentCarrierInfoPageForm = React.lazy(
  () => import('components/forms/CurrentCarrierInfoPageForm'),
);
const CurrentCarrierLimitsPageForm = React.lazy(
  () => import('components/forms/CurrentCarrierLimitsPageForm'),
);
const CurrentCarrierPageForm = React.lazy(
  () => import('components/forms/CurrentCarrierPageForm'),
);
const CurrentCarrierSinglePageForm = React.lazy(
  () => import('components/forms/CurrentCarrierSinglePageForm'),
);
const DefensiveDriverDiscountPageForm = React.lazy(
  () => import('components/forms/DefensiveDriverDiscountPageForm'),
);
const DiscountSummaryPageForm = React.lazy(
  () => import('components/forms/DiscountSummaryPageForm'),
);
const DriverAssignmentForm = React.lazy(
  () => import('components/forms/DriverAssignmentForm'),
);
const DriverDetailsPageForm = React.lazy(
  () => import('components/forms/DriverDetailsPageForm'),
);
const DwellingUsagePageForm = React.lazy(
  () => import('components/forms/DwellingUsagePageForm'),
);
const EffectiveDatePageForm = React.lazy(
  () => import('components/forms/EffectiveDatePageForm'),
);
const EntryPageForm = React.lazy(
  () => import('components/forms/EntryPageForm'),
);
const ErrorPageForm = React.lazy(
  () => import('components/forms/ErrorPageForm'),
);
const EvaluePageForm = React.lazy(
  () => import('components/forms/EvaluePageForm'),
);
const GaragingAddressConfirmationPageForm = React.lazy(
  () => import('components/forms/GaragingAddressConfirmationPageForm'),
);
const HouseholdMembersForm = React.lazy(
  () => import('components/forms/HouseholdMembersForm'),
);
const IncidentDetailsPageForm = React.lazy(
  () => import('components/forms/IncidentDetailsPageForm'),
);
const IncidentsListPageForm = React.lazy(
  () => import('components/forms/IncidentsListPageForm'),
);
const IncidentsPageForm = React.lazy(
  () => import('components/forms/IncidentsPageForm'),
);
const LandlordDetailsPageForm = React.lazy(
  () => import('components/forms/LandlordDetailsPageForm'),
);
const LongtermDwellingPageForm = React.lazy(
  () => import('components/forms/LongtermDwellingPageForm'),
);
const MailingAddressConfirmationPageForm = React.lazy(
  () => import('components/forms/MailingAddressConfirmationPageForm'),
);
const MembershipDiscountOptionForm = React.lazy(
  () => import('components/forms/MembershipDiscountOptionForm'),
);
const MembershipIdVerificationForm = React.lazy(
  () => import('components/forms/MembershipIdVerificationForm'),
);
const MembershipMemberSelectionSubForm = React.lazy(
  () => import('components/forms/MembershipMemberSelectionForm'),
);
const MultiPolicyDiscountPageForm = React.lazy(
  () => import('components/forms/MultiPolicyDiscountPageForm'),
);
const PaperlessConsentPageForm = React.lazy(
  () => import('components/forms/PaperlessConsentPageForm'),
);
const PaymentPlansPageForm = React.lazy(
  () => import('components/forms/PaymentPlansPageForm'),
);
const PipPageForm = React.lazy(() => import('components/forms/PipPageForm'));
const PipInsurerPageForm = React.lazy(
  () => import('components/forms/PipInsurerPageForm'),
);
const QualityGradePageForm = React.lazy(
  () => import('components/forms/QualityGradePageForm'),
);
const QuotePageForm = React.lazy(
  () => import('components/forms/QuotePageForm'),
);
const BundleQuestionPageForm = React.lazy(
  () => import('components/forms/BundleQuestionPageForm'),
);
const ResidenceTypePageForm = React.lazy(
  () => import('components/forms/ResidenceTypePageForm'),
);
const RetrieveQuotePageForm = React.lazy(
  () => import('components/forms/RetrieveQuotePageForm'),
);
const RideshareUsagePageForm = React.lazy(
  () => import('components/forms/RideshareUsagePageForm'),
);
const SelectDriversPageForm = React.lazy(
  () => import('components/forms/SelectDriversPageForm'),
);
const SelectVehiclesPageForm = React.lazy(
  () => import('components/forms/SelectVehiclesPageForm'),
);
const SmartDriverDiscountPageForm = React.lazy(
  () => import('components/forms/SmartDriverDiscountPageForm'),
);
const SprinklerDiscountPageForm = React.lazy(
  () => import('components/forms/SprinklerDiscountPageForm'),
);
const SquareFootagePageForm = React.lazy(
  () => import('components/forms/SquareFootagePageForm'),
);
const StudentDiscountPageForm = React.lazy(
  () => import('components/forms/StudentDiscountPageForm'),
);
const TellUsAboutYouForm = React.lazy(
  () => import('components/forms/TellUsAboutYouForm'),
);
const ThankYouPage = React.lazy(() => import('components/forms/ThankYouPage'));
const TheftAndFireAlarmDiscountPageForm = React.lazy(
  () => import('components/forms/TheftAndFireAlarmDiscountPageForm'),
);
const TravelinkDiscountPageForm = React.lazy(
  () => import('components/forms/TravelinkDiscountPageForm'),
);
const VehicleDetailsPageForm = React.lazy(
  () => import('components/forms/VehicleDetailsPageForm'),
);
const VehicleOwnershipPageForm = React.lazy(
  () => import('components/forms/VehicleOwnershipPageForm'),
);
const VehiclesGaragingAddressPageForm = React.lazy(
  () => import('components/forms/VehiclesGaragingAddressPageForm'),
);
const ViewSavedQuotePageForm = React.lazy(
  () => import('components/forms/ViewSavedQuotePageForm'),
);
const VinCollectPageForm = React.lazy(
  () => import('components/forms/VinCollectPageForm'),
);
const DwellingDetailsPage = React.lazy(
  () => import('components/forms/DwellingDetailsPage'),
);
const ConfirmationPage = React.lazy(
  () => import('components/pages/ConfirmationPage'),
);
const CurrentCarrierHowLongPage = React.lazy(
  () => import('components/pages/CurrentCarrierHowLongPage'),
);
const DiscountTransitionPage = React.lazy(
  () => import('components/pages/DiscountTransition'),
);
const MaintenancePage = React.lazy(
  () => import('components/pages/MaintenancePage'),
);
const PurchaseConfirmationPage = React.lazy(
  () => import('components/pages/PurchaseConfirmationPage'),
);
const RedirectPage = React.lazy(() => import('components/pages/RedirectPage'));
const RedirectToCompanyInfo = React.lazy(
  () => import('components/pages/RedirectToCompanyInfoPage'),
);
const RedirectToConfirmDriverLicense = React.lazy(
  () => import('components/pages/RedirectToCompanyInfoPage'),
);
const ThinkingPage = React.lazy(() => import('components/pages/ThinkingPage'));
const PropertyOptionalAmenities = React.lazy(
  () => import('components/forms/PropertyOptionalAmenitiesPage'),
);
const PropertyRenovations = React.lazy(
  () => import('components/forms/PropertyRenovationsPage'),
);
const MortgagePageForm = React.lazy(
  () => import('components/forms/MortgagePageForm'),
);
const UwQuestionsPageForm = React.lazy(
  () => import('components/forms/UwQuestionsPageForm'),
);
const MembershipConfirmationPage = React.lazy(
  () => import('components/forms/MembershipConfirmationForm'),
);

export type PageSegmentDetails = {
  trackLoad?: boolean;
  trackLoadName?: string;
};

export enum Tabs {
  AboutYou,
  WhatsCovered,
  Discounts,
  Quote,
  Purchase,
}

export type Page = {
  name?: string;
  path?: string;
  key?: string;
  component?: React.FC;
  tabIndex?: Tabs | false; //Note: False if no tab is associated - this is specified by @mui/material/Tabs.value
  segment?: PageSegmentDetails;
  exact?: boolean;
};
export type Pages = Page[];
export const pages = [
  {
    name: 'Entry',
    key: 'entry',
    path: '/',
    component: EntryPageForm,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'ErrorPage',
    key: 'errorPage',
    path: '/error',
    component: ErrorPageForm,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'PurchaseConfirmation',
    key: 'purchaseConfirmation',
    path: '/purchase-confirmation',
    component: PurchaseConfirmationPage,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'ViewSavedQuote',
    key: 'viewSavedQuote',
    path: '/view-saved-quote',
    component: ViewSavedQuotePageForm,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'RetrieveQuote',
    key: 'retrieveQuote',
    path: '/retrieve-quote',
    component: RetrieveQuotePageForm,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'TellUsAboutYou',
    key: 'tellUsAboutYou',
    path: '/tell-us-about-you',
    component: TellUsAboutYouForm,
    tabIndex: Tabs.AboutYou,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'Address',
    key: 'address',
    path: '/address',
    component: AddressPageForm,
    tabIndex: Tabs.AboutYou,
    segment: {
      trackLoad: true,
      trackLoadName: 'Quote Started',
    },
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'LongtermDwelling',
    key: 'longtermDwelling',
    path: '/longterm-dwelling',
    component: LongtermDwellingPageForm,
    tabIndex: Tabs.AboutYou,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'RetirementCommunity',
    key: 'retirementCommunity',
    path: '/retirement-community',
    component: DwellingUsagePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'PreviousAddress',
    key: 'previousAddress',
    path: '/previous-address',
    component: AddressPageForm,
    tabIndex: Tabs.AboutYou,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'Thinking',
    key: 'thinkingPage',
    path: '/thinking',
    component: ThinkingPage,
    tabIndex: Tabs.AboutYou,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS, PolicyType.RENTERS],
  },
  {
    name: 'SelectVehicles',
    key: 'selectVehicles',
    path: '/select-vehicles',
    component: SelectVehiclesPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'VehicleOwnership',
    key: 'vehicleOwnership',
    path: '/vehicle-ownership',
    component: VehicleOwnershipPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'VehicleDetails',
    key: 'vehicleDetails',
    path: '/vehicle-details',
    component: VehicleDetailsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'Rideshare',
    key: 'rideshare',
    path: '/rideshare',
    component: RideshareUsagePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'SelectDrivers',
    key: 'selectDrivers',
    path: '/select-drivers',
    component: SelectDriversPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'DriverDetails',
    key: 'driverDetails',
    path: '/driver-details',
    component: DriverDetailsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },

  {
    name: 'DriverAssignment',
    key: 'DriverAssignment',
    path: '/driver-assignment',
    component: DriverAssignmentForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },

  {
    name: 'CurrentCarrier',
    key: 'currentcarrier',
    path: '/current-carrier',
    component: CurrentCarrierPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'CurrentCarrierAAA',
    key: 'currentcarrierAAA',
    path: '/current-carrier-aaa',
    component: CurrentCarrierAaaPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'CurrentCarrierSingle',
    key: 'currentcarriersingle',
    path: '/current-carrier-single',
    component: CurrentCarrierSinglePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'CurrentCarrierInfo',
    key: 'currentcarrierinfo',
    path: '/current-carrier-info',
    component: CurrentCarrierInfoPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'CurrentCarrierLimits',
    key: 'currentcarrierlimits',
    path: '/current-carrier-limits',
    component: CurrentCarrierLimitsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'CurrentCarrierHowLong',
    key: 'currentcarrierhowlong',
    path: '/current-carrier-how-long',
    component: CurrentCarrierHowLongPage,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'Incidents',
    key: 'incidents',
    path: '/incidents',
    component: IncidentsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'IncidentDetails',
    key: 'incidentDetails',
    path: '/incident-details',
    component: IncidentDetailsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'IncidentList',
    key: 'incidentList',
    path: '/incident-details-list',
    component: IncidentsListPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.AUTO],
  },
  {
    name: 'DwellingUsage',
    key: 'dwellingUsage',
    path: '/dwelling-usage',
    component: DwellingUsagePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'Claims',
    key: 'claims',
    path: '/claims',
    component: ClaimsPageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'HouseholdMembers',
    key: 'houdholdMembers',
    path: '/household-members',
    component: HouseholdMembersForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'SquareFootage',
    key: 'squareFootage',
    path: '/square-footage',
    component: SquareFootagePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'QualityGrade',
    key: 'qualityGrade',
    path: '/quality-grade',
    component: QualityGradePageForm,
    tabIndex: Tabs.WhatsCovered,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'ResidenceType',
    key: 'residenceType',
    path: '/residence-type',
    component: ResidenceTypePageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO],
  },
  {
    name: 'MultiPolicyDiscount',
    key: 'multiPolicyDiscount',
    path: '/multi-policy-discount',
    component: MultiPolicyDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'DiscountTransition',
    key: 'discountTransition',
    path: '/discount-transition',
    component: DiscountTransitionPage,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'StudentDiscount',
    key: 'studentDiscount',
    path: '/student-discount',
    component: StudentDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'SmartDriverDiscount',
    key: 'smartDriverDiscount',
    path: '/smart-driver-discount',
    component: SmartDriverDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'DefensiveDriverDiscount',
    key: 'defensiveDriverDiscount',
    path: '/defensive-driver-discount',
    component: DefensiveDriverDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'TheftAndFireAlarmDiscount',
    key: 'theftAndFireAlarmDiscount',
    path: '/theft-and-fire-alarm-discount',
    component: TheftAndFireAlarmDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.HOMEOWNERS, PolicyType.RENTERS],
  },
  {
    name: 'SprinklerDiscount',
    key: 'sprinklerDiscount',
    path: '/sprinkler-discount',
    component: SprinklerDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.CONDO],
  },
  {
    name: 'TravelinkDiscount',
    key: 'travelinkDiscount',
    path: '/travelink-discount',
    component: TravelinkDiscountPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO],
  },
  {
    name: 'PipInsurance',
    key: 'pipInsurance',
    path: '/pip-insurance',
    component: PipPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO],
  },
  {
    name: 'BundleQuestion',
    key: 'bundleQuestion',
    path: '/bundle-question',
    component: BundleQuestionPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO],
  },
  {
    name: 'DiscountSummary',
    key: 'discountSummary',
    path: '/discount-summary',
    component: DiscountSummaryPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'Quote',
    key: 'quote',
    path: '/Quote',
    component: QuotePageForm,
    tabIndex: Tabs.Quote,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },

  {
    name: 'Confirmation',
    key: 'confirmation',
    path: '/confirmation',
    component: ConfirmationPage,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'Maintenance',
    key: 'maintenance',
    path: '/maintenance',
    component: MaintenancePage,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'ThankYou',
    key: 'thankYou',
    path: '/thank-you',
    component: ThankYouPage,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'Redirect',
    key: 'redirect',
    path: '/redirect',
    component: RedirectPage,
    tabIndex: false,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'EffectiveDate',
    key: 'effectiveDate',
    path: '/effective-date',
    component: EffectiveDatePageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'MembershipIdVerification',
    key: 'membershipIdVerification',
    path: '/membership-id-verification',
    component: MembershipIdVerificationForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'MembershipDiscountOption',
    key: 'membershipDiscountOption',
    path: '/membership-discount-option',
    component: MembershipDiscountOptionForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'MembershipMemberSelectionForm',
    key: 'membershipMemberSelectionForm',
    path: '/membership-driver-screen',
    component: MembershipMemberSelectionSubForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'Evalue',
    key: 'evalue',
    path: '/evalue',
    component: EvaluePageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'MembershipConfirmation',
    key: 'MembershipConfirmation',
    path: '/membership-confirmation',
    component: MembershipConfirmationPage,
    tabIndex: Tabs.Purchase,
    product: [
      PolicyType.AUTO,
      PolicyType.RENTERS,
      PolicyType.HOMEOWNERS,
      PolicyType.CONDO,
    ],
  },
  {
    name: 'LandlordDetails',
    key: 'landlordDetails',
    path: '/landlord-details',
    component: LandlordDetailsPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.RENTERS],
  },

  {
    name: 'CommercialPurposes',
    key: 'commercialPurposes',
    path: '/commercial-purposes',
    component: CommercialPurposesForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'RedirectToCompanyInfo',
    key: 'redirectToCompanyInfo',
    path: '/company-info-redirect',
    component: RedirectToCompanyInfo,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'CompanyInfo',
    key: 'companyInfo',
    path: '/company-info',
    component: CompanyInfoPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'PaperlessConsent',
    key: 'paperlessConsent',
    path: '/paperless-consent',
    component: PaperlessConsentPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'MailingAddressConfirmation',
    key: 'mailingAddressConfirmation',
    path: '/mailing-address-confirmation',
    component: MailingAddressConfirmationPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'GaragingAddressConfirmation',
    key: 'garagingAddressConfirmation',
    path: '/garaging-address-confirmation',
    component: GaragingAddressConfirmationPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'VehiclesGaragingAddress',
    key: 'vehiclesGaragingAddress',
    path: '/vehicles-garaging-address',
    component: VehiclesGaragingAddressPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'VinCollectPageForm',
    key: 'vinCollectPageForm',
    path: '/vin-collect',
    component: VinCollectPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'RedirectToConfirmDriverLicense',
    key: 'redirectToConfirmDriverLicense',
    path: '/confirm-driver-license-redirect',
    component: RedirectToConfirmDriverLicense,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'ConfirmDriverLicense',
    key: 'confirmDriverLicense',
    path: '/confirm-driver-license',
    component: ConfirmDriverLicensePageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
  },
  {
    name: 'ConstructionTypes',
    key: 'constructionTypes',
    path: '/construction-types',
    component: ConstructionTypesPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.RENTERS],
  },
  {
    name: 'PipInsurer',
    key: 'pipInsurer',
    path: '/pip-insurer',
    component: PipInsurerPageForm,
    tabIndex: Tabs.Discounts,
    product: [PolicyType.AUTO],
  },
  {
    name: 'AdditionalInsured',
    key: 'additionalInsured',
    path: '/additional-insured',
    component: AdditionalInsuredPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.RENTERS, PolicyType.HOMEOWNERS],
  },
  {
    name: 'UwQuestions',
    key: 'uwQuestions',
    path: '/uw-questions',
    component: UwQuestionsPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'DwellingDetailsPage',
    key: 'dwellingDetailsPage',
    path: '/dwelling-details',
    component: DwellingDetailsPage,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'PropertyRenovations',
    key: 'propertyRenovations',
    path: '/renovations',
    component: PropertyRenovations,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'MortgagePageForm',
    key: 'mortgagePageForm',
    path: '/mortgage',
    component: MortgagePageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'PropertyOptionalAmenities',
    key: 'propertyOptionalAmenities',
    path: '/optional-amenities',
    component: PropertyOptionalAmenities,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.HOMEOWNERS],
  },
  {
    name: 'QuoteBind',
    key: 'quoteBind',
    path: '/QuoteBind',
    component: QuotePageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO, PolicyType.HOMEOWNERS],
  },
  {
    name: 'PaymentPlans',
    key: 'paymentPlans',
    path: '/payment-plans',
    component: PaymentPlansPageForm,
    tabIndex: Tabs.Purchase,
    product: [PolicyType.AUTO],
    exact: false,
  },
] as const;

export type PageName = (typeof pages)[number]['name'];
export type PageKey = (typeof pages)[number]['key'];
export type PagePath = (typeof pages)[number]['path'];
export const getPageByName = (name: PageName): Page =>
  pages?.find((obj) => obj.name === name) || {};
export const getPageByPath = (path: string): Page =>
  pages?.find((obj) => obj.path === path) || {};
export const getPathByName = (name: PageName): PagePath | '' =>
  pages?.find((obj) => obj.name === name)?.path || '';
export const getNameByPath = (path: string): PageName | '' =>
  pages?.find((obj) => obj.path === path)?.name || '';
export const getKeyByName = (name: PageName): PageKey | '' =>
  pages?.find((obj) => obj.name === name)?.key || '';
export const getKeyByPath = (path: string): PageKey | '' =>
  pages?.find((obj) => obj.path === path)?.key || '';
export const getNameByKey = (key: PageKey): PageName | '' =>
  pages?.find((obj) => obj.key === key)?.name || '';
export const getTabIndexByPath = (path: string): Tabs | false =>
  pages?.find((obj) => obj.path === path)?.tabIndex ?? false;
export const getTabIndexByName = (name: PageName): Tabs | false =>
  pages?.find((obj) => obj.name === name)?.tabIndex ?? false;
export const getIndexByPath = (path: string): number =>
  pages?.findIndex((obj) => obj.path === path);
export const getIndexByName = (name: PageName): number =>
  pages?.findIndex((obj) => obj.name === name);
export const getSegmentDetailsByPath = (path: string): PageSegmentDetails => {
  const page = getPageByPath(path);
  return (
    page.segment || {
      trackLoad: undefined,
      trackLoadName: undefined,
    }
  );
};

export default pages;
