import { PolicyType } from 'api/schema/insured.schema';
import moment, { MomentInput } from 'moment';
import * as yup from 'yup';
import { getNameByKey } from '../helpers/pages';
import { ClubContextState } from './contexts/ClubContext';
import { InsuredContextState } from './contexts/InsuredContext';

const OLDEST_VEHICLE_YEAR = 1981;

export const VIN_REGEX = /^\w{17}$/i;
export const VIN_REGEX_LETTERS = /^[^oiq]*$/i;
export const NAME_REGEX = /^(?=.{1,25}$)[a-zA-Z\s']+(?:--?[a-zA-Z\s']+)*$/;
export const NAME_REGEX_LASTNAME =
  /^(?=.{1,50}$)[a-zA-Z\s']+(?:--?[a-zA-Z\s']+)*$/; //Allowing 50 chars for lastname
export const NAME_REGEX_PAYMENT = /^[a-zA-Z][a-zA-Z\s'\-,\.]+$/; //eslint-disable-line
export const EMAIL_REGEX =
  /^[a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~]+(\.[a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~]+)*@[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,6}$|^$/i; // eslint-disable-line
export const APARTMENT_REGEX = /^([a-zA-Z]|[0-9]|#|\s)+$/g;
export const MIN_LENGTH_FOR_ACCOUNT_NAME = 3;
export const MAX_LENGTH_FOR_ACCOUNT_NAME = 50;
export const DIGITS_FOR_ROUTING_NUMBER = 9;
export const MIN_DIGITS_FOR_ACCOUNT_NUMBER = 4;
export const MAX_DIGITS_FOR_ACCOUNT_NUMBER = 17;
export const MIN_LENGTH_FOR_CARD_NAME = 3;
export const MAX_LENGTH_FOR_CARD_NAME = 50;
export const MIN_DIGITS_FOR_CARD_NUMBER = 13;
export const MAX_DIGITS_FOR_CARD_NUMBER = 19;
export const LENGTH_FOR_EXPIRYDATE = 5;
export const MAX_DIGITS_FOR_ZIPCODE = 5;
export const MIN_DIGITS_FOR_MEMBERSHIP_NUMBER = 16;
export const MAX_DIGITS_FOR_MEMBERSHIP_NUMBER = 16;
export const MAX_LENGTH_MORTGAGE_LENDER_NAME = 50;
export const MAX_LENGTH_MORTGAGE_LOAN_NUMBER = 20;
export const REGEX_MORTAGE_DETAILS =
  /^([a-zA-Z0-9()_@$.,:;/ `‘’'"“”!?*+-]| & |[a-zA-Z]&[a-zA-Z](?![a-zA-Z]+;)|[0-9]%(?![0-9a-zA-Z]))+$/; //eslint-disable-line
export const REGEX_MORTGAGE_LOAN_NUMBER = /^[A-Za-z0-9\-.:/ ]+$/;

type AgeInYMD = {
  years: number;
  months: number;
  days: number;
};

const getAgeInYMD = (
  value: Date | null | undefined,
  todaysDate: moment.Moment = moment(),
): AgeInYMD => {
  const d: moment.Moment = moment(value);
  const years: number = todaysDate.diff(d, 'years');
  d.add(years, 'years');
  const months: number = todaysDate.diff(d, 'months');
  d.add(months, 'months');
  const days: number = todaysDate.diff(d, 'days');
  return { years, months, days };
};

export const checkMinAge = (
  minAge: number,
  todaysDate: moment.Moment = moment(),
) =>
  function (value: Date | null | undefined) {
    // const age: number = todaysDate.diff(moment(value), 'years', true);
    // getting precision to the day, since using the above yields variable results since it's a float
    const age: AgeInYMD = getAgeInYMD(value, todaysDate);
    const aboveMinDate: boolean =
      age.years > minAge ||
      (age.years === minAge && age.days >= 0 && age.months >= 0);
    return aboveMinDate;
  };

export const isFutureDate = (value: MomentInput) => {
  const today = moment();
  const date = moment(value);
  return date.isAfter(today);
};

export const isOlderThanInYears = (
  value: MomentInput,
  compareTo: MomentInput,
  numYears: number,
): boolean => {
  const date = moment(value);
  const compare = moment(compareTo);

  const yearsDifference = compare.diff(date, 'years', true);
  return yearsDifference > numYears;
};

export const checkAgeRange =
  (
    minAge: number,
    maxAge: number,
    // I don't understand the reason why the max birthday is included,
    // but this is causing issues for disjoint ranges,
    // so I added this optional parameter
    // ** THIS IS BACKWARD COMPATIBLE WITH THE EXISTING CODE **
    includeMaxBirthday = true,
    todaysDate: moment.Moment = moment(),
  ) =>
  (value: Date | null | undefined) => {
    // const age: number = todaysDate.diff(moment(value), 'years', true);
    // getting precision to the day, since using the above yields variable results since it's a float
    const age: AgeInYMD = getAgeInYMD(value, todaysDate);
    const aboveMinDate: boolean =
      age.years > minAge ||
      (age.years === minAge && age.days >= 0 && age.months >= 0);
    const belowMaxDate: boolean =
      age.years < maxAge ||
      (includeMaxBirthday &&
        age.years === maxAge &&
        age.days === 0 &&
        age.months === 0);
    const valid: boolean = aboveMinDate && belowMaxDate;
    return valid;
  };

const checkMileageValidity = (
  value: string | null | undefined,
  type: 'annual' | 'oneWay',
) => {
  if (!value) return true;
  const milesInNumbers = Number(value.replace(/\D/g, ''));
  return (
    milesInNumbers >= (type === 'annual' ? 1000 : 1) &&
    milesInNumbers <= (type === 'annual' ? 99999 : 999)
  );
};

const checkPhoneNumber = (value: string | null | undefined) => {
  if (!value) return false;
  const formatted = value.replace(/\D/g, '');
  return formatted.length === 10;
};

const checkInitialDateOfBirth = checkAgeRange(18, 100);
const checkAgeRange16to100 = checkAgeRange(16, 100);

export type UseTranslateT = (s: string) => string;

export const getIsZipCodeValid = (zipcode?: string | null) => {
  const isCorrectLength = zipcode?.length === 5;
  const isAllZeroes = Number(zipcode) === 0;
  const isValid = isCorrectLength && !isAllZeroes && !isNaN(+zipcode);
  return isValid;
};

export const getIsAlphaNumeric = (input?: string | null) => {
  const isValid = /^[A-Za-z0-9 ,.]+$/.test(input || '');
  return isValid;
};

export const getIsCityValid = (input?: string | null) => {
  const isValid = /^[A-Za-z ,.]+$/.test(input || '');
  return isValid;
};

const getIsAddressValid = (address?: string | null) => {
  const isValid = /^[0-9]+ [A-Za-z0-9 ,.]+$/.test(address || '');
  return isValid;
};

const getMinimumAgeNonPNI = (clubState = '') => {
  if (['AZ', 'DE', 'ID', 'KS', 'NY', 'OR', 'UT'].includes(clubState)) return 14;
  else if ('CA' === clubState) return 16;
  else return 15;
};

export const checkOnlyPositiveNumeric = (input?: string | null) =>
  /^[0-9]+$/.test(input || '');

const checkExpirationDate = (expirationDate?: string | null) => {
  if (expirationDate) {
    const checkLength = expirationDate.length === 5;
    const checkFormat = /([0-9]{2})\/([0-9]{2})/.test(expirationDate);
    const creditCardDate = moment(expirationDate, 'MM/YY');

    const today = moment();
    const isValidExpiryDate =
      creditCardDate.isValid() && today < creditCardDate.add(1, 'months');
    return checkLength && checkFormat && isValidExpiryDate;
  } else return false;
};

export const validations = (
  t: UseTranslateT,
  _insuredState?: InsuredContextState,
  clubState?: ClubContextState,
  policyType?: PolicyType,
): { [key: string]: yup.ObjectSchema } => {
  const tp = (key: string) => t(`validations.${key}`);

  const zipValidation = yup
    .string()
    .required(tp('invalidZipCode'))
    .test('zipcode', tp('invalidZipCode'), getIsZipCodeValid);
  const cityValidation = yup
    .string()
    .required(tp('invalidCity'))
    .test('alphanumeric', tp('invalidCity'), getIsCityValid);
  const stateValidation = yup.string().required(tp('invalidState')).nullable();

  return {
    [getNameByKey('entry')]: yup.object().shape({
      zipcode: yup
        .string()
        .test('zipcode', tp('invalidZipCode'), getIsZipCodeValid),
    }),
    [getNameByKey('tellUsAboutYou')]: yup.object().shape({
      firstName: yup
        .string()
        .ensure()
        .matches(NAME_REGEX, tp('invalidFirstName'))
        .required(tp('required')),
      lastName: yup
        .string()
        .ensure()
        .matches(NAME_REGEX_LASTNAME, tp('invalidLastName'))
        .required(tp('required')),
      dateOfBirth: yup
        .date()
        .nullable()
        .required(tp('required'))
        .typeError(tp('validDate'))
        .test(
          'minAge',
          tp('driverMinAge').replace('<Insert Age>', '18'),
          policyType === PolicyType.AUTO
            ? checkMinAge(18, moment())
            : () => {
                return true;
              },
        )
        .test(
          'ageRange',
          tp('ageRangeInvalid').replace('<Insert Age>', '18'),
          checkAgeRange(18, 100),
        ),
      maritalStatus: yup.string().ensure().required(tp('required')),
      occupation: yup.string().ensure().required(tp('required')),
      coapplicant: yup.boolean(),
    }),
    [getNameByKey('viewSavedQuote')]: yup.object().shape({
      retrieveQuote: yup.object().shape({
        email: yup
          .string()
          .ensure()
          .required(tp('invalidEmail'))
          .matches(EMAIL_REGEX, tp('invalidEmail')),
        dateOfBirth: yup
          .date()
          .nullable()
          .required(tp('invalidDateOfBirth'))
          .typeError(tp('invalidDateOfBirth'))
          .test(
            'ageRange',
            clubState?.state === 'CA'
              ? tp('ageRangeInvalid').replace('<Insert Age>', '18')
              : tp('ageRangeInvalid').replace('<Insert Age>', '18'),
            clubState?.state === 'CA'
              ? checkAgeRange16to100
              : checkInitialDateOfBirth,
          ),
        zipcode: yup
          .string()
          .test('zipcode', tp('invalidZipCode'), getIsZipCodeValid),
      }),
    }),
    ...[
      getNameByKey('address'),
      getNameByKey('previousAddress'),
      getNameByKey('mailingAddressConfirmation'),
      getNameByKey('vehiclesGaragingAddress'),
      getNameByKey('companyInfo'),
    ].reduce(
      (acc, key) => ({
        ...acc,
        [key]: yup.object().shape({
          address: yup
            .string()
            .ensure()
            .required(tp('required'))
            .min(3, tp('invalidAddressLength')),
          previousAddress: yup.string().min(3, tp('invalidAddressLength')),
          mailingAddress: yup
            .string()
            .ensure()
            .required(tp('required'))
            .min(3, tp('invalidAddressLength')),
          garagingAddress: yup
            .string()
            .ensure()
            .required(tp('required'))
            .min(3, tp('invalidAddressLength')),
          thirdPartyAddress: yup
            .string()
            .ensure()
            .required(tp('required'))
            .min(3, tp('invalidAddressLength')),
          apartment: yup.string().matches(APARTMENT_REGEX, tp('invalidInput')),
          previousApartment: yup
            .string()
            .matches(APARTMENT_REGEX, tp('invalidInput')),
          mailingApartment: yup
            .string()
            .matches(APARTMENT_REGEX, tp('invalidInput')),
          garagingApartment: yup
            .string()
            .matches(APARTMENT_REGEX, tp('invalidInput')),
          thirdPartyApartment: yup
            .string()
            .matches(APARTMENT_REGEX, tp('invalidInput')),
          lineHolderName: yup
            .string()
            .required(tp('invalidCompanyName'))
            .test('alphanumeric', tp('invalidCompanyName'), getIsAlphaNumeric),
          lineHolderNameOther: yup
            .string()
            .required(tp('invalidCompanyName'))
            .test('alphanumeric', tp('invalidCompanyName'), getIsAlphaNumeric),
          poBoxAddress: yup.object().shape({
            pobox: yup
              .string()
              .required(tp('invalidPOBox'))
              .test(
                'checkPoBoxPositiveNumeric',
                tp('invalidPOBox'),
                checkOnlyPositiveNumeric,
              ),
            state: stateValidation,
            city: cityValidation,
            zip: zipValidation,
          }),
          poBoxMailingAddress: yup.object().shape({
            pobox: yup
              .string()
              .required(tp('invalidPOBox'))
              .test(
                'checkPoBoxPositiveNumeric',
                tp('invalidPOBox'),
                checkOnlyPositiveNumeric,
              ),
            state: stateValidation,
            city: cityValidation,
            zip: zipValidation,
          }),
          bypassAddress: yup.object().shape({
            ...[
              'current',
              'previous',
              'garagingAddress',
              'mailingAddress',
              'thirdPartyAddress',
            ].reduce(
              (acc, key) => ({
                ...acc,
                [key]: yup.object().shape({
                  streetAddress: yup
                    .string()
                    .ensure()
                    .required(tp('invalidAddress'))
                    .min(3, tp('invalidAddressLength'))
                    .test(
                      'alphanumeric',
                      tp('invalidAddress'),
                      getIsAddressValid,
                    ),
                  zip: zipValidation,
                  city: cityValidation,
                  state: stateValidation,
                }),
              }),
              {},
            ),
          }),
        }),
      }),
      {},
    ),
    [getNameByKey('selectVehicles')]: yup.object().shape({
      vehicles: yup
        .array()
        .of(yup.string())
        .ensure()
        .required(tp('required'))
        .min(1),
      rideshareDaysPerWeek: yup
        .number()
        .required(tp('minRideshareDaysPerWeek'))
        .moreThan(0, tp('minRideshareDaysPerWeek')),
      rideshareRidesPerWeek: yup
        .number()
        .required(tp('minRideshareRidesPerWeek'))
        .moreThan(0, tp('minRideshareRidesPerWeek')),
      annualMiles: yup
        .string()
        .required(tp('required'))
        .test('annualMiles', tp('invalidAnnualMiles'), (val) =>
          checkMileageValidity(val, 'annual'),
        ),
      milesOneWay: yup
        .string()
        .required(tp('required'))
        .test('milesOneWay', tp('invalidMilesOneWay'), (val) =>
          checkMileageValidity(val, 'oneWay'),
        ),
    }),
    [getNameByKey('rideshare')]: yup.object().shape({
      rideshareDaysPerWeek: yup
        .number()
        .required(tp('minRideshareDaysPerWeek'))
        .moreThan(0, tp('minRideshareDaysPerWeek')),
      rideshareRidesPerWeek: yup
        .number()
        .required(tp('minRideshareRidesPerWeek'))
        .moreThan(0, tp('minRideshareRidesPerWeek')),
    }),
    [getNameByKey('discountSummary')]: yup.object().shape({
      email: yup
        .string()
        .ensure()
        .matches(EMAIL_REGEX, tp('invalidEmail'))
        .required(tp('required')),
      cellNumber: yup
        .string()
        .required(tp('required'))
        .test('numberLength', tp('invalidCellNumber'), checkPhoneNumber),
    }),
    [getNameByKey('errorPage')]: yup.object().shape({
      email: yup.string().ensure().matches(EMAIL_REGEX, tp('invalidEmail')),
      cellNumber: yup
        .string()
        .required(tp('required'))
        .test('numberLength', tp('invalidCellNumber'), checkPhoneNumber),
    }),
    [getNameByKey('paperlessConsent')]: yup.object().shape({
      paperlessEmail: yup
        .string()
        .ensure()
        .matches(EMAIL_REGEX, tp('invalidEmail')),
      textAlertsPhone: yup
        .string()
        .required(tp('required'))
        .test('numberLength', tp('invalidCellNumber'), checkPhoneNumber),
    }),
    [getNameByKey('confirmDriverLicense')]: yup.object().shape({
      driverEmail: yup
        .string()
        .ensure()
        .matches(EMAIL_REGEX, tp('invalidEmail')),
      driverCellNumber: yup
        .string()
        .test(
          'numberLength',
          tp('invalidTelematicsCellNumber'),
          checkPhoneNumber,
        )
        .test('usPhoneNumber', tp('invalidTelematicsCellNumber'), function () {
          return this.parent.isUSPhoneNumber;
        }),
    }),
    [getNameByKey('squareFootage')]: yup.object().shape({
      squareFootage: yup
        .number()
        .required(tp('minSquareFootage'))
        .moreThan(0, tp('minSquareFootage'))
        .typeError(tp('invalidSquareFootageType')),
      yearBuilt: yup
        .number()
        .required(tp('blankError'))
        .moreThan(0, tp('yearBuiltLessThan4'))
        .test('length', tp('yearBuiltLessThan4'), (val) => {
          return val?.toString().length === 4;
        })
        .lessThan(new Date().getFullYear() + 1, tp('yearBuiltLessThan4'))
        .typeError(tp('yearBuiltLessThan4')),
    }),
    [getNameByKey('currentcarrierhowlong')]: yup.object().shape({
      yearsWithPriorInsurer: yup
        .number()
        .required(tp('invalidCarrierYearsSelection')),
    }),
    [getNameByKey('vehicleDetails')]: yup.object().shape({
      annualMiles: yup
        .string()
        .required(tp('required'))
        .test('annualMiles', tp('invalidAnnualMiles'), (val) =>
          checkMileageValidity(val, 'annual'),
        ),
      milesOneWay: yup
        .string()
        .required(tp('required'))
        .test('milesOneWay', tp('invalidMilesOneWay'), (val) =>
          checkMileageValidity(val, 'oneWay'),
        ),
    }),
    ...[getNameByKey('driverDetails'), getNameByKey('selectDrivers')].reduce(
      (routes, routeName) => ({
        ...routes,
        [routeName]: yup.object().shape({
          ageFirstLicensed: yup
            .number()
            .required(tp('required'))
            .typeError(tp('driverAgeType'))
            .min(
              clubState?.state === 'CA'
                ? 16
                : clubState?.state === 'PA'
                  ? 15
                  : 14,
              clubState?.state === 'CA'
                ? tp('driverMinAge').replace('<Insert Age>', '16')
                : clubState?.state === 'PA'
                  ? tp('driverMinAge').replace('<Insert Age>', '15')
                  : tp('driverMinAge').replace('<Insert Age>', '14'),
            )
            .max(100, tp('driverMaxAge')),
          birthDate: yup
            .date()
            .nullable()
            .required(tp('required'))
            .typeError(tp('validDate'))
            .test(
              'minAge',
              tp('driverMinAge').replace(
                '<Insert Age>',
                getMinimumAgeNonPNI(clubState?.state).toString(),
              ),
              checkMinAge(getMinimumAgeNonPNI(clubState?.state), moment()),
            )
            .test(
              'ageRange',
              tp('ageRangeInvalid').replace(
                '<Insert Age>',
                getMinimumAgeNonPNI(clubState?.state).toString(),
              ),
              checkAgeRange(getMinimumAgeNonPNI(clubState?.state), 100),
            ),
          relationship: yup.string().ensure().required(tp('required')),
        }),
      }),
      {}, // routes accumulator
    ),
    [getNameByKey('DriverAssignment')]: yup.object().shape({
      driverAssignment: yup
        .array()
        .of(
          yup.object().shape({
            assignee: yup.string().required(),
            assignment: yup.string().ensure().required(tp('required')),
          }),
        )
        .required(tp('required')),
    }),
    [getNameByKey('incidentDetails')]: yup.object().shape({
      incidentDriver: yup.string().ensure().required(tp('required')),
      incidentType: yup.string().ensure().required(tp('required')),
      incidentYear: yup.string().ensure().required(tp('required')),
    }),
    [getNameByKey('membershipIdVerification')]: yup.object().shape({
      membershipNumber: yup
        .string()
        .ensure()
        .required(tp('invalidMembershipNumber'))
        .min(MIN_DIGITS_FOR_MEMBERSHIP_NUMBER, tp('invalidMembershipNumber'))
        .max(MAX_DIGITS_FOR_MEMBERSHIP_NUMBER, tp('invalidMembershipNumber'))
        .test(
          'checkMembershipNumberOnlyNumeric',
          tp('invalidMembershipNumber'),
          checkOnlyPositiveNumeric,
        ),
    }),
    [getNameByKey('mortgagePageForm')]: yup.object().shape({
      newLoan: yup.boolean().required(tp('required')),
      isAvailedMortgage: yup.boolean().required(tp('required')),
      purchaseDate: yup
        .date()
        .nullable()
        .required(tp('required'))
        .typeError(tp('invalidPurchasseDate')),
      mortgageeDetails: yup.object().shape({
        mortgageeName: yup
          .string()
          .required(tp('required'))
          .max(MAX_LENGTH_MORTGAGE_LENDER_NAME, tp('invalidInputFieldLength'))
          .matches(REGEX_MORTAGE_DETAILS, tp('invalidLenderName')),
        loanNumber: yup
          .string()
          .required(tp('required'))
          .max(MAX_LENGTH_MORTGAGE_LOAN_NUMBER, tp('invalidLoanNumberLength'))
          .matches(REGEX_MORTGAGE_LOAN_NUMBER, tp('invalidLoanNumber')),
      }),
      mailingAddress: yup
        .string()
        .ensure()
        .required(tp('required'))
        .min(3, tp('invalidAddressLength')),
      poBoxMailingAddress: yup.object().shape({
        pobox: yup
          .string()
          .required(tp('invalidPOBox'))
          .test(
            'checkPoBoxPositiveNumeric',
            tp('invalidPOBox'),
            checkOnlyPositiveNumeric,
          ),
        state: stateValidation,
        city: cityValidation,
        zip: zipValidation,
      }),
    }),
  };
};

interface modalValidationOptionTypes {
  addVehicle: {
    vinsToRender: (string | undefined)[];
  };
}

export const modalValidations = (
  t: UseTranslateT,
  options?: modalValidationOptionTypes,
) => {
  const tp = (key: string) => t(`validations.${key}`);
  return {
    vehicleDetails: yup
      .object()
      .concat(validations(t, {})[getNameByKey('vehicleDetails')])
      .concat(validations(t, {})[getNameByKey('rideshare')]),
    addDriver: yup.object().shape({
      firstName: yup
        .string()
        .ensure()
        .required(tp('required'))
        .matches(NAME_REGEX, tp('invalidFirstName')),
      lastName: yup
        .string()
        .ensure()
        .required(tp('required'))
        .matches(NAME_REGEX, tp('invalidLastName')),
    }),
    addVehicle: yup.object().shape({
      vin: yup
        .string()
        .required(tp('required'))
        .ensure()
        .matches(VIN_REGEX_LETTERS, tp('invalidVINLetters'))
        .matches(VIN_REGEX, tp('invalidVINLength'))
        .test('checkIfDuplicateVin', tp('duplicateVin'), (value) =>
          value ? !options?.addVehicle?.vinsToRender?.includes(value) : true,
        ),

      year: yup
        .string()
        .ensure()
        .required(tp('required'))
        .test(
          'checkVehicleYearIsInRange',
          tp('invalidVehicleMinimumYear'),
          function (value) {
            return value ? parseInt(value) >= OLDEST_VEHICLE_YEAR : false;
          },
        )
        .test(
          'checkVehicleYearIsInRange',
          tp('invalidVehicleMaximumYear'),
          function (value) {
            return value
              ? parseInt(value) <= new Date().getFullYear() + 1
              : false;
          },
        ),
      make: yup.string().ensure().required(tp('required')),
      model: yup.string().ensure().required(tp('required')),
    }),
    saveQuote: yup.object().shape({
      email: yup
        .string()
        .required(tp('invalidEmail'))
        .ensure()
        .matches(EMAIL_REGEX, tp('invalidEmail')),
      cellNumber: yup
        .string()
        .required(tp('invalidCellNumber'))
        .test('numberLength', tp('invalidCellNumber'), checkPhoneNumber),
    }),
    checkingSavingsAccount: () =>
      yup.object().shape({
        accountName: yup
          .string()
          .ensure()
          .required(tp('invalidName'))
          .min(MIN_LENGTH_FOR_ACCOUNT_NAME, tp('invalidAccountName'))
          .max(MAX_LENGTH_FOR_ACCOUNT_NAME, tp('invalidAccountName'))
          .matches(NAME_REGEX_PAYMENT, tp('invalidAccountName')),
        // routingNumber: NOTE: this field will be validated at field level since it will call API
        accountNumber: yup
          .string()
          .ensure()
          .required(tp('invalidAccountNumber'))
          .min(MIN_DIGITS_FOR_ACCOUNT_NUMBER, tp('invalidAccountNumber'))
          .max(MAX_DIGITS_FOR_ACCOUNT_NUMBER, tp('invalidAccountNumber'))
          .test(
            'checkAccountNumberOnlyNumeric',
            tp('invalidAccountNumber'),
            checkOnlyPositiveNumeric,
          ),
      }),
    debitorCreditCard: yup.object().shape({
      nameOnCard: yup
        .string()
        .ensure()
        .required(tp('invalidName'))
        .min(MIN_LENGTH_FOR_CARD_NAME, tp('invalidCardName'))
        .max(MAX_LENGTH_FOR_CARD_NAME, tp('invalidCardName'))
        .matches(NAME_REGEX_PAYMENT, tp('invalidCardName')),
      cardNumber: yup
        .string()
        .ensure()
        .required(tp('invalidCardNumber'))
        .min(MIN_DIGITS_FOR_CARD_NUMBER, tp('invalidCardNumber'))
        .max(MAX_DIGITS_FOR_CARD_NUMBER, tp('invalidCardNumber'))
        .test(
          'checkCardNumberrOnlyNumeric',
          tp('invalidCardNumber'),
          checkOnlyPositiveNumeric,
        ),
      expirationDate: yup
        .string()
        .test(
          'checkValidExpirationDate',
          tp('invalidExpirationDate'),
          checkExpirationDate,
        ),
      zipCode: yup
        .string()
        .test('checkZipCode', tp('invalidZipCode'), getIsZipCodeValid),
    }),
  };
};

export const policyNumberValidation = (
  t: UseTranslateT,
  input: string | undefined,
  healthInsurerPolicyNumber: string | undefined,
): string | undefined => {
  input = input?.includes('*') ? healthInsurerPolicyNumber : input;

  const sequencePattern =
    /^(?!(?:01234|12345|23456|34567|45678|56789|67890|98765|87654|76543|65432|54321|43210)).*$/;
  const isNotallowed = input && !sequencePattern.test(input);

  const repeatedStrRegex = new RegExp(`([0-9A-Z])\\1{4}`, 'g');
  const isRepeatedStr = !!input
    ?.toUpperCase()
    .split('')
    .sort()
    .join('')
    .match(repeatedStrRegex);

  const isStartsWithYHZorYKU =
    input?.toUpperCase().startsWith('YHZ') ||
    input?.toUpperCase().startsWith('YKU');
  const message = isStartsWithYHZorYKU
    ? t('validations.isMedicarePolicyNumber')
    : t('validations.invalidPolicyNumber');

  const isValid =
    /^[A-Za-z0-9*]+$/.test(input?.toUpperCase() || '') &&
    !isNotallowed &&
    !isRepeatedStr &&
    !isStartsWithYHZorYKU;
  return isValid ? undefined : message;
};

export default validations;
