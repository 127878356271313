import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerProps } from '@mui/material';
import { OnStartOver } from 'helpers/form';
import { resetGtmDataLayer } from 'helpers/gtm';
import { getPathByName } from 'helpers/pages';
import Cookies from 'universal-cookie';

const defaultTimeoutMs = 2000;
interface ClearCookiesParams {
  featureFlags?: boolean;
  initiatedQuote?: boolean;
  isBindFlow?: boolean;
  issueQuoteData?: boolean;
  queryParam?: boolean;
  sessionId?: boolean;

  /**
   * Delay by 2 seconds before removing the cookies.
   *
   * @default false
   * @type {boolean|undefined}
   */
  delay?: boolean;
}

const clearCookiesFunction = (clearOptions: ClearCookiesParams) => {
  const {
    featureFlags = true,
    initiatedQuote = true,
    isBindFlow = true,
    issueQuoteData = true,
    queryParam = true,
    sessionId = true,
  } = clearOptions;

  window.dispatchEvent(new CustomEvent('beforeunload'));
  const cookies = new Cookies();
  cookies.remove('token');
  cookies.remove('token_adc');
  cookies.remove('melissaToken');
  cookies.remove('dismissProactive');
  cookies.remove('adcFlow');
  cookies.remove('retrieveQuoteFlow');
  cookies.remove('hasExited');
  cookies.remove('segmentIdSet');
  cookies.remove('isBundleQuestionAnswered');
  cookies.remove('locationKeys');
  cookies.remove('visitedPages');
  cookies.remove('abTesting');
  cookies.remove('visitedAgentHelp');

  // Cookies that have the option to not be removed if required
  featureFlags && cookies.remove('featureFlags');
  initiatedQuote && cookies.remove('initiatedQuote');
  isBindFlow && cookies.remove('isBindFlow');
  issueQuoteData && cookies.remove('issueQuoteData');
  queryParam && cookies.remove('queryParam');
  sessionId && cookies.remove('sessionId');
};

/**
 * Clear cookies.
 * @param clearOptions option to enable/disable to remove cookie. Default is true for all.
 */
export const clearCookies = (clearOptions: ClearCookiesParams = {}) => {
  const { delay = false } = clearOptions;

  if (delay) {
    setTimeout(() => clearCookiesFunction(clearOptions), defaultTimeoutMs);
  } else {
    clearCookiesFunction(clearOptions);
  }
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const useStartOver = () => {
  const history = useHistory();

  const startOver = useCallback(() => {
    resetGtmDataLayer();
    clearCookies();
    clearLocalStorage();
    history.push(getPathByName('Entry'));
    location.reload(); // refresh the dom
  }, [history]);

  return { startOver };
};

// Force remount component
export const Remounter: React.FC<ContainerProps> = (props) => {
  const [isOn, setIsOn] = useState(true);
  useEffect(() => {
    if (!isOn) setIsOn(true);
  }, [isOn]);
  useEffect(() => {
    OnStartOver.subscribe(() => setIsOn(false));
  }, []);
  return <>{isOn ? props.children : null}</>;
};
