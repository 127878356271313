export * from '@aaa-ncnu-ie/ez-quote-api-driver';

// Enum dependencies are not guaranteed to be stable when imported directly from the schema.
// This is why we override them so that when API adds updates the UI code doesn't break.
export enum Source {
  PREFILLED = 'PREFILLED',
  USER_ADDED = 'USER_ADDED',
}

export enum SaveAssignmentType {
  DriverToVehicle = 'DriverToVehicle',
  VehicleToDriver = 'VehicleToDriver',
}

export enum GetAssignmentType {
  Auto = 'Auto',
  DriverToVehicle = 'DriverToVehicle',
  VehicleToDriver = 'VehicleToDriver',
}
